import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/styles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Cards from "../components/Cards";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import HeaderSection from "../components/HeaderSection";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
  },

  html: {
    textAlign: "justify",
    "& p": {
      "& strong": {
        color: theme.palette.secondary.main,
      },
    },
  },
  htmlBold: {
    textAlign: "justify",
    fontWeight: 600,
  },
  select: {
    width: 300
  },
  container: {
    // marginTop: theme.spacing(10),
    gap: theme.spacing(6),
  },
}));

const AlumniRoll = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { edges: posts } = data.allMarkdownRemark;
  const [course, setCourse] = React.useState("all");
  const cards = posts.filter(({ node: post }) => {
    return course === "all" || post.frontmatter.course.includes(course);
  }).map(({ node: post }) => {
    return {
      title: post.frontmatter.title,
      text: post.frontmatter.date,
      image: post.frontmatter.frontImage,
      description: post.frontmatter.description,
      course: post.frontmatter.course,
      cohort: post.frontmatter.cohort,
      quote: post.frontmatter.quote,
      date: post.frontmatter.date,
      link: post.fields.slug,
      author: post.frontmatter.author,

    }
  })


  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  // const shareUrl = "http://github.com";
  // const title = "GitHub";
  const handleChange = (event) => {
    setCourse(event.target.value);
  };

  const url = "https://www.codeacademyberlin.com";

  const linkedinShare = (post) => {
    const newWindow = window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}/${post.fields.slug}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const facebookShare = (post) => {
    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}/${post.fields.slug}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Grid container
      // spacing={10}
      // direction="column"
      alignItems="center"
      justifyContent="center"
      maxWidth="lg"
      className={classes.container}
    >
      <Grid container
        justifyContent="space-around"
      >
        <Grid item xs={12} md={7} justifyContent="flex-start" >
          {/* <HeaderSection header="What our alumni say" /> */}
          <Typography
            component="h3"
            variant="h3"
            align={smDown ? "center" : "left"}
            // color="inherit"
            gutterBottom
          >
            What our alumni say
          </Typography>
        </Grid>
        <Grid item container xs={12} md={3} justifyContent="center">
          <FormControl className={classes.formControl}>
            <InputLabel id="course-select-label">Course</InputLabel>
            <Select
              labelId="course-select-label"
              id="course-select"
              value={course}
              onChange={handleChange}
              className={classes.select}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Data Analytics">Data Analytics</MenuItem>
              <MenuItem value="Web Development">Web Development</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Cards cards={cards} variant="longOverlay" primary={true} />
    </Grid>
  );
};
//   return (
//     <Grid container
//       // spacing={10}
//       direction="column"
//       alignItems="center"
//       justifyContent="center"
//       maxWidth="lg"
//       className={classes.container}
//     >

//       <Cards cards={{ blurbs: cards }} variant="content" primary={true} />
//       {posts &&
//         posts.map(
//           ({ node: post }) =>
//             !post.frontmatter.archived && (
//               <Grid item xs={12} md={6} lg={4} key={post.id}>
//                 <Card className={classes.root}>
//                   <CardActionArea component={Link} to={post.fields.slug}>
//                     {post.frontmatter.image && (
//                       <CardMedia
//                         children={
//                           <PreviewCompatibleImage
//                             className={classes.media}
//                             imageInfo={{
//                               image: post.frontmatter.image,
//                               alt: `featured image thumbnail for alumni testimonial ${post.frontmatter.title}`,
//                               style: { height: "150px" },
//                             }}
//                           />
//                         }
//                       />
//                     )}
//                     <CardContent>
//                       <Typography gutterBottom variant="h4" component="h2">
//                         {post.frontmatter.title}
//                       </Typography>
//                       <Typography
//                         variant="body1"
//                         color="textSecondary"
//                         component="p"
//                       >
//                         Graduated in <strong>{post.frontmatter.date}</strong> with the <strong>{post.frontmatter.cohort}</strong> cohort
//                       </Typography>
//                       <Typography className={classes.html}>
//                         {post.frontmatter.description}
//                       </Typography>
//                       <Typography className={classes.htmlBold}>
//                         {'"'}
//                         {post.frontmatter.quote} {'"'}
//                       </Typography>
//                     </CardContent>
//                   </CardActionArea>
//                   <CardActions>
//                     <Button
//                       size="small"
//                       component={Link}
//                       to={post.fields.slug}
//                       color="primary"
//                     >
//                       Keep Reading
//                     </Button>
//                     {/* <Grid
//                       container
//                       spacing={0}
//                       style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
//                     > */}
//                     {/* <Typography
//                         variant="body1"
//                         color="primary"
//                         component="p"
//                       >share</Typography> */}
//                     <Button
//                       onClick={() => linkedinShare(post)}
//                       size="small"
//                       color="primary"
//                     >
//                       <LinkedInIcon fontSize="large" />
//                     </Button>
//                     <Button
//                       onClick={() => facebookShare(post)}
//                       size="small"
//                       color="primary"
//                     >
//                       <FacebookIcon fontSize="large" />
//                     </Button>
//                     {/* </Grid> */}
//                   </CardActions>
//                 </Card>
//               </Grid>
//             )
//         )}
//     </Grid>
//   );
// };

AlumniRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const query = () => (
  <StaticQuery
    query={graphql`
      query AlumniRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "alumni-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                cohort
                course
                templateKey
                date(formatString: "MMMM YYYY")
                featuredpost
                description
                quote
                archived
                frontImage {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <AlumniRoll data={data} count={count} />}
  />
);

export default query