import React from "react";
import Container from "@material-ui/core/Container";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import AlumniRoll from "../../components/AlumniRoll";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
  },
}));
const AlumniIndexPage = ({ location }) => {



  const classes = useStyles();
  return (
    <Layout location={location} title="Alumni">
      <SEO
        title={"What our Alumni says"}
        location={location}
        description={
          "Check what our alumni says about our school and where they are working at the moment!"
        }
      />
      <Container maxWidth="lg" className={classes.root}>

        <AlumniRoll />
      </Container>
    </Layout>
  );
};
export default AlumniIndexPage;
